<template>
  <div id="app">
    <el-container>
      <el-header class="side-top">
        <el-row>
          <el-col :span="24">
            <div>
              <el-row>
                <el-col :span="12">
                  <el-button v-if="isCollapse" type="text" @click="menuChickBar(false)">
                    <i class="el-icon-s-unfold" style="font-size: 30px"></i>
                  </el-button>
                  <el-button v-else type="text" @click="menuChickBar(true)">
                    <i class="el-icon-s-fold" style="font-size: 30px"></i>
                  </el-button>
                  <span class="top">Liych Object Manager System</span>
                </el-col>
                <el-col :span="12">
                  <div class="admin">
                    <i class="el-icon-user-solid" style="padding-right: 15px"></i>
                    <span>{{ UserName }}</span>
                    <el-link style="padding-left: 15px;color: white" @click="editPasswd">修改密码</el-link>
                    <el-link style="padding-left: 15px;color: white" @click="clearToken">退出</el-link>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>
      </el-header>
<!--      <el-row class="el-row">-->
<!--        <el-col :span="5">-->
<!--          <el-aside class="el-aside side-bar">-->
<!--            <left />-->
<!--          </el-aside>-->
<!--        </el-col>-->
<!--        <el-col :span="19">-->
<!--          <el-main class="el-main" style="padding-top: 80px">-->
<!--            <router-view class="pl-5" />-->
<!--          </el-main>-->
<!--        </el-col>-->
<!--      </el-row>-->
      <el-aside class="el-aside side-bar" :width="isCollapse?'70px':'270px'">
        <div class="el-menu">
          <el-menu
              default-active="/dynamic"
              class="el-menu-vertical-demo"
              :unique-opened = "true"
              :collapse="isCollapse"
              :collapse-transition="false"
              background-color="#545c64"
              text-color="#fff"
              active-text-color="#ffd04b"
              router="router">
            <el-menu-item index="/dynamic">
              <i class="el-icon-document"></i>
              <span slot="title">每日动态</span>
            </el-menu-item>
            <!--      <div v-for="(item,index) in menu" :key="index">-->
            <!--        <el-submenu :index="index + ''">-->
            <!--          <template slot="title">-->
            <!--            <i :class=item.menuIcon></i>-->
            <!--            <span slot="title" style="text-transform: uppercase">{{ item.menuName }}</span>-->
            <!--          </template>-->
            <!--          <el-menu-item style="height: 40px"  v-for="(children,index1) in item.menuChildren" :key="index1">-->
            <!--            <el-menu-item class="menu-font-smail" :index=children.menuChildrenUri>{{ children.menuName }}</el-menu-item>-->
            <!--          </el-menu-item>-->
            <!--        </el-submenu>-->
            <!--      </div>-->
            <el-submenu v-for="(item,index) in menu" :key="index" :index="index + ''">
              <template slot="title">
                <i :class=item.menuIcon></i>
                <span slot="title" style="text-transform: uppercase">{{ item.menuName }}</span>
              </template>
              <el-menu-item style="height: 40px"  v-for="(children,index1) in item.menuChildren" :key="index1">
                <el-menu-item class="menu-font-smail" :index=children.menuChildrenUri>{{ children.menuName }}</el-menu-item>
              </el-menu-item>
            </el-submenu>
          </el-menu>
        </div>
      </el-aside>
      <div v-if="isCollapse" style="margin-left: 70px"><router-view class="el-main" /></div>
      <div v-else style="margin-left: 270px"><router-view class="el-main" /></div>
    </el-container>

  </div>
</template>

<script>
// import top from "@/components/reception/top"
// import left from "@/components/reception/left";

export default {
  name: 'App',
  components: {
    // top,
    // left
  },
  data() {
    return {
      menu: [
      ],
      isCollapse: false,
      UserName: window.localStorage.getItem('UserName').toUpperCase()
    }
  },
  mounted() {
    this.menuList(window.localStorage.getItem("UserName"))
  },
  methods: {
    menuChickBar(is) {
      this.isCollapse = is;
    },
    menuList(userName) {
      this.$http({
        method: 'get',
        url: '/api/user/menuListByUser/' + userName
      }).then((res)=> {
        this.menu = res.data.data.menu
      })
    },
    clearToken () {
      this.$confirm('退出登录？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var _this = this
        localStorage.clear();
        _this.$router.push('/login');
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消操作'
        })
      })
    },
    editPasswd(){
      this.$router.push("/editPassWd")
    }
  }
}
</script>

<style>
html,body,#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}
.el-header {
  background-color: #657891;
  color: #333;
  line-height: 60px;
  margin: 0;
  font-size: 16px;
}
.el-main {
  color: #2b2b2b;
  width: 100%;
  height: 100%;
  padding-top: 80px;
}
.el-row {
  display: flex;
  flex-wrap: wrap;
}

.pl-5 {
  padding-left: 5px;
}
.side-top {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  overflow: hidden;
  z-index: 99;
}
.el-menu {
  height: 100%;
  width: 95%;
}
.menu-font-smail {
  font-size: 10px;
  height: 40px;
  line-height: 40px;
}
.el-aside {
  height: 100%;
  transition: width 0.15s;
  -webkit-transition: width 0.15s;
  -moz-transition: width 0.15s;
  -o-transition: width 0.15s;
}

.side-bar {
  position: fixed;
  left: 0;
  top: 60px;
  bottom: 0;
  overflow: hidden;
  z-index: 98;
}
.top {
  font-size: 36px;
  color: #B3C0D1;
  padding-left: 50px;
}
.admin {
  color: #ffffff;
  text-align: right;
}
</style>
